import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class BooksPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      search: ""
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange({ target: { value }}){
      this.setState({ search: value})
  }

  render() {
    const { data } = this.props
    const { search } = this.state
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    const searchRegEx = new RegExp(search, "i");
    const filteredPosts = posts.filter(({ node: { frontmatter }}) => searchRegEx.test(frontmatter.title));
    const filteredNotes = filteredPosts.filter(({ node }) => node.frontmatter.type === "idea-note")
    const filteredBooks = filteredPosts.filter(({ node }) => node.frontmatter.type === "book")

    const booksByCategory = filteredBooks.reduce((categories, { node }) => {      
      const category = categories.find(category => category.name === node.frontmatter.tags[1]);
    
      if (category){
        category.posts.push(node);
        // keep top rated first
        category.posts.sort((a, b) => b.frontmatter.rating - a.frontmatter.rating);
      }
      else categories.push({ name: node.frontmatter.tags[1], posts: [node]});

      return categories;
    }, [])

    // NOTE: if I want to add rating filter
    // let postsByRating = filteredPosts.reduce((categories, { node }) => {      
    //   const category = categories.find(category => category.name === node.frontmatter.rating);
    
    //   if (category) category.posts.push(node);
    //   else categories.push({ name: node.frontmatter.rating, posts: [node]});

    //   return categories;
    // }, [])
    // postsByRating = postsByRating.sort((a, b) => b.name - a.name);

    // NOTE: can make this a flip switch between both
    // const postsToShow = postsByRating;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO 
          title="Notes" 
          description="Notes on books I've read, podcasts I've listened to and people I admire."
        />

        <h1 className="page-header">Notes</h1>
        <p>This is an experiment in sharing notes. I occasionally collect ideas from books I've read, people I admire and podcasts I've listened to. They are written for myself, but if you're like me, you may find them useful too.</p>
        <p>Disclaimer - Notes are often in shorthand. I can't guarantee my notes will make sense for everyone. Spelling mistakes likely.</p>
        <input className="book-search" type="text" placeholder="Search notes..." value={this.state.search} onChange={this.onChange}></input>

        <h3>People</h3>
        <div className="people">
          {filteredNotes.map(note => {
            const title = note.node.frontmatter.title.slice(10)
            
            return (
              <div key={note.node.fields.slug} className="post">
                <h4>
                  <Link to={note.node.fields.slug}>{title}</Link>
                </h4>
              </div>
            )
          })}
        </div>

        <h3>Books</h3>
        <div className="books">
          {booksByCategory.map((category, i) => {
            return (
              <section key={i}>
                  <h4>{category.name}</h4>
                  {category.posts.map(post => {
                    
                    return (
                      <div key={post.fields.slug} className="post">
                        <h4>
                          <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                          <span className="rating">  ({post.frontmatter.rating})</span>
                        </h4>
                      </div>
                    )
                  })}
              </section>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default BooksPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { 
          type: { in: ["book", "idea-note"] }
        } 
      }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            type
            rating
          }
        }
      }
    }
  }
`
